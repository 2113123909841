import { Link } from 'gatsby';
import parse from 'html-react-parser';
// import ReactPlayer from 'react-player';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Stream } from '@cloudflare/stream-react';
import { getTranslationByKey } from '../helpers/translation-helper';
import ApartmentsContext from '../utils/context';
import Floorplan from './Floorplan';

export default function ResidencesPageUpperSection({ data, lang, dir }) {
  const { translations } = React.useContext(ApartmentsContext);
  const [lastSegment, setLastSegment] = React.useState('');

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      let url = window.location.href;
      if (url.endsWith('/')) {
        url = url.slice(0, -1);
      }
      const segment = url.split('/').pop();
      setLastSegment(segment);
    }
  }, []);

  return (
    <section
      className="video-banner room-page residence_page_section relative overflow-hidden"
      lang={lang}
    >
      {data.featuredVideo ? (
        <div className="video absolute inset-x-0 z-1 overflow-hidden h-0">
          <Stream
            controls={false}
            src={data.featuredVideo}
            height="100%"
            width="100%"
            autoplay
            loop
            responsive
          />
          {/* <ReactPlayer
            url={`${data.featuredVideo}?autoplay=1&mute=1&playsinline&rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0&loop=1&playlist=${data.vid}`}
            playing
            controls={false}
            loop
            muted
            playsinline
            width="100%"
            className="absolute z-1 w-full h-full rct_player"
          /> */}
        </div>
      ) : (
        <div className="bg-top inner-page-banner absolute inset-x-0 z-1">
          <LazyLoadImage
            src={data.featuredImage?.url}
            width={1920}
            height={1080}
            alt=""
            placeholderSrc={`${data.featuredImage?.url}?width=50&quality=50`}
            effect="blur"
          />
        </div>
      )}

      <div className="container mx-auto relative z-3 md:py-20 py-5">
        <div className="grid md:grid-cols-12 pt-20">
          <div className="col-span-12 md:col-span-10 md:ltr:ml-20 md:ltr:pl-5 md:rtl:mr-20 md:rtl:pr-5 pt-20 pb-5">
            <h1 className="pt-20 ltr:font-din rtl:font-tahoma subheadline-text ltr:tracking-space12 font-semibold uppercase mb-5">
              {data.title}
            </h1>
            <div className="text-24-35 md:text-38-52 ltr:font-georgia rtl:font-DroidArabicNaskh intro-text">
              {data.headerIntro ? parse(data.headerIntro) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="container room-page-container mx-auto relative z-3">
        <div className="bg-gray93 text-gray py-5 px-6 sm:py-10 md:pb-14 sm:px-12 lg:pt-12 lg:pb-24 lg:px-24">
          <div className="grid grid-cols-4 md:gap-7 gap-3 md:mb-6 description_section">
            <div className="col-span-4 lg:col-span-3 ltr:md:text-18-32 rtl:md:text-16-30 text-13-22">
              <p className="ltr:font-din rtl:font-tahoma subheadline-text ltr:tracking-space12 text-gold font-semibold uppercase mb-3">
                {getTranslationByKey(translations, 'Description')}
              </p>
              <p>{data.roomDescription}</p>
            </div>
          </div>

          <div className="grid grid-cols-12 md:gap-7">
            <div
              className="col-span-12 sm:col-span-6 lg:col-span-4 ltr:md:text-18-32 rtl:md:text-16-30 text-13-22 room_pagedescription"
              dir={dir}
            >
              <p className="ltr:font-din rtl:font-tahoma subheadline-text ltr:tracking-space12 font-semibold text-gold uppercase mb-2">
                {getTranslationByKey(translations, 'Room Details')}:
              </p>
              {data.roomDetails ? parse(data.roomDetails) : null}
            </div>
            <div
              className="col-span-12 sm:col-span-6 lg:col-span-4 ltr:md:text-18-32 rtl:md:text-16-30 text-13-22 room_pagedescription"
              dir={dir}
            >
              <p className="ltr:font-din rtl:font-tahoma subheadline-text ltr:tracking-space12 font-semibold text-gold uppercase mb-3">
                {getTranslationByKey(translations, 'Included Services')}:
              </p>
              {data.includedServices ? parse(data.includedServices) : null}
            </div>
            <div
              className="col-span-12 sm:col-span-6 lg:col-span-4 ltr:md:text-18-32 rtl:md:text-16-30 text-13-22 mb-3 room_pagedescription"
              dir={dir}
            >
              <p className="ltr:font-din rtl:font-tahoma subheadline-text ltr:tracking-space12 font-semibold text-gold uppercase mb-3">
                {getTranslationByKey(translations, 'Paid Services')}:
              </p>
              {data.additionalBenefits ? parse(data.additionalBenefits) : null}
            </div>
          </div>

          <div className="grid grid-cols-1 gap-7 md:mb-12 mb-5 md:pt-5">
            <div className="col-span-1 text-18-32">
              <p className="ltr:font-din rtl:font-tahoma subheadline-text ltr:tracking-space12 text-gold font-semibold uppercase md:mb-3 mb-6">
                {getTranslationByKey(translations, 'Residential Services')}:
              </p>
              <div
                className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-7 mb-6 business_guestwrapper"
                dir={dir}
              >
                {data.businessAndGuestServices
                  ? data.businessAndGuestServices.map((item, index) => (
                      <div className="service-list col-span-1" key={index} dir={dir}>
                        {item.content.iconImage ? (
                          <LazyLoadImage
                            src={item.content.iconImage.url}
                            width={26}
                            height={26}
                            alt={item.content.title}
                            placeholderSrc={`${item.content.iconImage.url}?width=5&quality=50`}
                            effect="blur"
                          />
                        ) : null}
                        <span className="text-13-16 text_part">
                          {item.content.title ? parse(item.content.title) : null}
                        </span>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>

          <div
            className="bg-dark-gray relative md:px-16 px-10 md:pt-12 md:pb-16 py-5 md:mb-20 mb-10 reservation_form"
            dir={dir}
          >
            <div className="booking-form grid md:grid-cols-12 md:gap-7 gap-5">
              <div className="col-span-7 lg:ml-10 lg:pl-5">
                <h2 className="ltr:font-georgia rtl:font-DroidArabicNaskh text-gold h2_tag">
                  {getTranslationByKey(translations, 'Leasing Options')}
                </h2>
              </div>
              <div className="col-span-7 lg:ml-10 lg:pl-5 pb-5 text-13-22 ltr:md:text-18-32 rtl:md:text-16-30 text-white">
                {data.leasingOptions ? parse(data.leasingOptions) : null}
              </div>
              <div className="col-span-5 lg:ml-10 ltr:lg:pl-5 ltr:pr-5 rtl:lg:pr-5 rtl:pl-5 reserve_button">
                <div className="form-action-wrapper">
                  <Link
                    to={`/${lang}/location/#contact-us`}
                    className="text-white mb-4 md:mb-8 block bg-gold text-center uppercase btn-highlighted leasing-options-contact-us"
                    data-buttons={lastSegment}
                  >
                    {getTranslationByKey(translations, 'Contact Us')}
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="columns-1 sm:columns-2 gap-5 md:gap-7">
            {data.gallery
              ? data.gallery.slice(0, 3).map((item, index) => {
                  const height = index === 2 ? 750 : 360;
                  const heightSet = index === 2 ? 75 : 36;
                  return (
                    <div
                      className={`${
                        index === 2
                          ? 'img-taller row-span-2 mb-5 md:mb-7'
                          : 'img-wider mb-5 md:mb-7'
                      }`}
                      key={`gallery${index}`}
                    >
                      <LazyLoadImage
                        src={`${item.url}?width=570&height=${height}&mode=crop&quality=100`}
                        width={570}
                        height={height}
                        alt=""
                        placeholderSrc={`${item.url}?width=57&height=${heightSet}&mode=crop&quality=50`}
                        effect="blur"
                      />
                    </div>
                  );
                })
              : null}
          </div>

          {data.gallery.length > 3 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 md:gap-7">
              {data.gallery.slice(3, data.gallery.length).map((item, index) => (
                <div className="img-wider" key={`gallery${index}`}>
                  <LazyLoadImage
                    src={`${item.url}?width=570&height=360&mode=crop&quality=100`}
                    width={570}
                    height={360}
                    alt=""
                    placeholderSrc={`${item.url}?width=57&height=36&mode=crop&quality=50`}
                    effect="blur"
                  />
                </div>
              ))}{' '}
            </div>
          ) : null}
        </div>
      </div>
      <Floorplan />
    </section>
  );
}
